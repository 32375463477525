import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["LocaleSwitcher"] */ "/workspace/src/app/[locale]/components/navigation/footer/LocaleSwitcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewsletterSubscribe"] */ "/workspace/src/app/[locale]/components/navigation/footer/NewsletterSubscribe.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/app/[locale]/components/navigation/NavLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RouteListener"] */ "/workspace/src/app/[locale]/components/navigation/RouteListener.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/app/[locale]/components/navigation/SignInLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CookieBanner"] */ "/workspace/src/app/[locale]/components/shared/CookieBanner.tsx");
