"use client";

import { useEffect } from "react";
import { NewsletterModal } from "../../shared/modals/NewsletterModal";
import { event } from "@/app/lib/gtag";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { setNewsletterModalIsOpen } from "@/store/reducers/newsletterModalSlice";

const NEWSLETTER_TIMER = 40000;
const COOKIE = "KTZ-has_seen_time_newsletter_modal";

export function NewsletterSubscribe({
  children,
}: {
  children: React.ReactNode;
}) {
  const dispatch = useAppDispatch();
  const newsletterModalIsOpen = useAppSelector(
    (state: any) => state.newsletterModal,
  );

  useEffect(() => {
    const hasSeenPopup = localStorage.getItem(COOKIE);
    if (!hasSeenPopup) {
      setTimeout(() => {
        dispatch(setNewsletterModalIsOpen(true));
        localStorage.setItem(COOKIE, "true");
      }, NEWSLETTER_TIMER);
    }
  }, []);

  return (
    <>
      <button
        className="btn btn-outline btn-primary btn-sm border-2 bg-neutral"
        onClick={() => {
          dispatch(setNewsletterModalIsOpen(true));
          event("Clicked NL CTA", {
            event_category: "Footer",
            event_label: "",
            value: 0.5,
          });
        }}
      >
        {children}
      </button>
      {newsletterModalIsOpen && (
        <NewsletterModal
          handleClose={() => {
            dispatch(setNewsletterModalIsOpen(false));
          }}
        />
      )}
    </>
  );
}
